import logoMono from '../assets/logo monoquick.png';
import logoRevolution from '../assets/logo fashion revolution.png';
import logoCosh from '../assets/logo cosh.png';
import logoRepairYourRepair from '../assets/repair-your-repair.png';
import planetLogo from '../assets/planet-logo.png';
import trustefFlipLogo from '../assets/trusted-flip.png';
import counsilLogo from '../assets/fashion-counsil.png';
import ebayLogo from '../assets/ebay.png';

export const brandsTailorPageLogos = [
    {
        img: logoRevolution,
        width: '80px',
        marginRight: '25px',
    },
    {
        img: ebayLogo,
        width: '130px',
        marginRight: '25px',
    },
    {
        img: trustefFlipLogo,
        width: '200px',
        marginRight: '25px',
    },
    {
        img: counsilLogo,
        width: '150px',
        marginRight: '25px',
    },
    {
        img: logoMono,
        width: '200px'
    },
    {
        img: logoRepairYourRepair,
        width: '200px',
        marginRight: '25px',
    },
    {
        img: planetLogo,
        width: '200px',
        marginRight: '25px',
    },
    {
        img: logoCosh,
        width: '100px'
    },
]
import logos from './assets/logos.png';
import visaLogo from './assets/visa-logo.png';
import villageLogo from './assets/village-capital-logo.png';

import ecodesignLogo from './assets/ecodesign.png';
import impactLogo from './assets/impact-factory.png';
import ministryLogo from './assets/ministry.png';
import logoCosh from './assets/logo-cosh.png';
import flipLogo from './assets/flip-logo.png';
import fashionCounsil from './assets/fashion-counsil.png';
import ebayLogo from './assets/ebay.png';

export const brandsHomePage = [
    {
        img: impactLogo,
        width: '150px',
    },
    {
        img: flipLogo,
        width: '100px',
    },
    {
        img: ecodesignLogo,
        width: '250px'
    },
    {
        img: ministryLogo,
        width: '200px',

    },
    {
        img: logoCosh,
        width: '100px'
    },
    {
        img: villageLogo,
        width: '250px',

    },
    {
        img: fashionCounsil,
        width: '150px',

    },
    {
        img: ebayLogo,
        width: '150px',

    },
    {
        img: visaLogo,
        width: '100px',

    },
]
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBackLink from '../../../components/BackLink';
import { selectProductItems } from '../../store/selectors';
import formatPriceCent from '../../../utils/format-price-cent';
import line from './line.svg';
import cookies from 'js-cookie';
import i18n from 'i18n';
import { Helmet } from 'react-helmet';

// styles
const useStyles = makeStyles((theme) => {
  return {
    central: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontWeight: 500,
    },
    price: {
      padding: theme.spacing(0, 1),
      backgroundImage: `url(${line})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '98% 1em',
    },
  };
});

// props
interface HeaderProps {
  categoryId?: string; // TODO: make mandatory
  typeId?: string;
  repairId?: string;
  priceCent?: number;
  backLinkHref?: string;
}

/**
 * Product header component.
 */
export default function ProductHeader({ categoryId, typeId, repairId, priceCent, backLinkHref = '..' }: HeaderProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const langCookie = cookies.get('language') || '';
  const products = useSelector(selectProductItems);

  if (!products) {
    console.error('Products array is null or undefined.');
    return null;
  }

  const categoryMatch = products.find((x) => x.productCategory.seo?.[i18n.language] === categoryId);
  const typeMatch = products.find((x) => x.productType.seo?.[i18n.language] === typeId && x.productCategory.seo?.[i18n.language] === categoryId);
  const repairMatch = products.find((x) => x.seo?.[i18n.language] === repairId && x.productCategory.seo?.[i18n.language] === categoryId && x.productType.seo?.[i18n.language] === typeId);
  // console.log('Matched category:', categoryMatch);
  // console.log('Matched type:', typeMatch);
  // console.log('Matched repair:', repairMatch);

  const categoryName = categoryMatch?.productCategory.title;
  const typeName = typeMatch?.productType.title;
  const repairName = repairMatch?.name;

  const repairNameMetaDescription = repairMatch?.metadescription;
  const typeNameMetaDescription = typeMatch?.productType.metadescription;
  const categoryNameMetaDescription = categoryMatch?.productCategory.metadescription;

  console.log('Repair name meta description:', repairNameMetaDescription);
  // console.log('Type name meta description:', typeNameMetaDescription);
  // console.log('Category name meta description:', categoryNameMetaDescription);

  const metaDescription =
    repairNameMetaDescription?.[langCookie] ||
    typeNameMetaDescription?.[langCookie] ||
    categoryNameMetaDescription?.[langCookie];

  console.log('Final meta description:', metaDescription);

  if (!metaDescription) {
    console.warn('Meta description is undefined. Check the backend response or key mapping.');
  }

  return (
    <Box component="header" display="flex">
      <Helmet>{metaDescription && <meta name="description" content={metaDescription} />}</Helmet>
      <Box mr={2}>
        <AppBackLink to={backLinkHref} />
      </Box>
      <div className={classes.central}>
        <Typography component="h2" variant="h4">
          {!langCookie ? categoryName?.de : categoryName?.[langCookie]}
        </Typography>
        {typeName && (
          <Typography variant="body1">
            {t('Item Type')}: {!langCookie ? typeName.de : typeName[langCookie]}
          </Typography>
        )}
        {repairName && (
          <Typography variant="body1">
            {t('Type of Repair')}: {!langCookie ? repairName.de : repairName[langCookie]}
          </Typography>
        )}
      </div>
      <div>
        {priceCent && (
          <Typography className={classes.price} component="div" variant="h3">
            {formatPriceCent(priceCent)}
          </Typography>
        )}
      </div>
    </Box>
  );
}

import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LanguageToggle from 'components/CheckUserLanguage/CheckUserLanguage';

export default function AppNotFound(props: RouteComponentProps & { text?: string }) {
  const { t } = useTranslation();
  return (
    <Box pt={4} data-testid="AppNotFound">
      <Container maxWidth="md">
        <Typography className="with-bar" variant="h1">
          {t('Not Found')}
        </Typography>
        <Box mb={{ xs: 3, md: 4 }} />
        <Paper>
          <Box p={{ xs: 0, sm: 1, md: 2 }}>
            <Typography variant="body1">{props.text ? t(props.text) : t("This page doesn't exist.")}</Typography>
            <Box mb={3} />
            <Box textAlign={{ xs: 'center', md: 'left' }}>
              <Button color="secondary" component={Link} to="/" variant="contained">
                {t('Back to the Homepage')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <LanguageToggle />
    </Box>
  );
}

import React from 'react';
import { useNavigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localizePath } from 'utils/pages';
import { blogItemsDe, blogItemsEn } from 'Blog/data/items';


const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguage = async () => {
      const currentLanguage = i18n.language;

      const { pathname, search } = window.location;

      const localizedPath = localizePath(pathname, currentLanguage, blogItemsEn, blogItemsDe);

      navigate(localizedPath + search);
      setLoading(false);
    };

    fetchLanguage();
    
  }, [navigate, i18n.language]);

  return (
    <div>
      {loading && <div>Loading...</div>}
    </div>
  );
};

export default LanguageToggle;
import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

interface RepairImage {
  file: File; // Add the `file` property as a File type
  fileName: string;
  fileType: string;
  fileSize: number;
  fileContent: string;
  creationTime: number;
}

type UtilsContextType = {
  images: RepairImage[];
  setImages: React.Dispatch<React.SetStateAction<RepairImage[]>>;
  repairCreationTimes: number;
  setRepairCreationTimes: React.Dispatch<React.SetStateAction<number>>;

  deleteRepairImages: (repairCreationTime: number | undefined) => void;
};

const initialFunction = () => {};

export const UtilsContext = createContext<UtilsContextType>({
  images: [],

  setImages: initialFunction,
  repairCreationTimes: 0,
  setRepairCreationTimes: initialFunction,
  deleteRepairImages: initialFunction,
});

export const UtilsProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [images, setImages] = useState<RepairImage[]>([]);
  const [repairCreationTimes, setRepairCreationTimes] = useState<number>(0);

  const deleteRepairImages = (repairCreationTime: number | undefined) => {
    setImages((prevImages) => prevImages.filter((image) => image.creationTime !== repairCreationTime));
  };

  const value = {
    images,
    setImages,
    repairCreationTimes,
    setRepairCreationTimes,

    deleteRepairImages,
  };

  return <UtilsContext.Provider value={value}>{children}</UtilsContext.Provider>;
};

export const useUtilsContext = () => {
  return useContext(UtilsContext);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as TikTok } from '../../images/svg/tiktok.svg';
import { ReactComponent as Instagram } from '../../images/svg/instagram.svg';
import { ReactComponent as Facebook } from '../../images/svg/facebook.svg';
import { ReactComponent as LinkedIn } from '../../images/svg/linkedin.svg';
import { ReactComponent as WhatsApp } from '../../images/svg/whatsapp.svg';
import classes from './Footer.module.css';
import paypalLogo from './paypal.png';
import dhlLogo from './dhl.png';
import { getPage } from '../../utils/pages';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="container pt-5 pb-5">
      <div className={classes.footerWrapper}>
        <div className={classes.main}>
          <div className={classes.footerNavigationItem}>
            <h5 className={classes.footerColumnTitle}>REPAIR REBELS</h5>
            <Link className={classes.footerNavigationLink} to={getPage('brandPartnerships')}>
              {t('B2B Partnerships')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('forCraftsmen')}>
              {t('ForCraftsmen')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('aboutUs')}>
              {t('About Us')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('jobs')}>
              Jobs
            </Link>
          </div>
          <div className={classes.footerNavigationItem}>
            <h5 className={classes.footerColumnTitle}>FOR CUSTOMERS</h5>
            <Link className={classes.footerNavigationLink} to={getPage('priceGuide')}>
              {t('PriceGuide')}
            </Link>
            <Link className={classes.footerNavigationLink} to={`${getPage('voucher')}${getPage('select')}`}>
              {t('MenuVouchers')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('club')}>
              Super Rebel Club
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('blogList')}>
              {t('Tips & More')}
            </Link>
          </div>
          <div className={classes.footerNavigationItem}>
            <h5 className={classes.footerColumnTitle}>{t('LEGAL')}</h5>
            <Link className={classes.footerNavigationLink} to={getPage('imprint')}>
              {t('Imprint')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('privacyPolicy')}>
              {t('Privacy Policy')}
            </Link>
            <Link className={classes.footerNavigationLink} to={getPage('terms')}>
              {t('Terms and Conditions')}
            </Link>
          </div>
        </div>
        <div className={classes.logosGroup}>
          <div className={classes.paypalImageBlock}>
            <LazyLoadImage className={classes.paypal} alt="PayPal" src={paypalLogo} width={140} height={60} effect="blur" />
          </div>
          <div className={classes.paypalImageBlock}>
            <LazyLoadImage className={classes.paypal} alt="Dhl" src={dhlLogo} width={140} height={60} effect="blur" />
          </div>
        </div>
        <div className={classes.mobileContactBlock}>
          <div className={classes.contactBlock}>
            <div className={classes.contact}>{t('Contact Information - Contact Us')}</div>
            <a href="mailto:hello@repair-rebels.com" className={classes.contact}>
              hello@repair-rebels.com
            </a>
            <a href="tel:+49 173 5204782" className={classes.contact}>
              +49 173 5204782
            </a>
          </div>
          <div>
            <div className="text-right mb-4 mb-md-3">
              <a className={classes.socialLink} href="https://www.instagram.com/repair_rebels" target="_blank" rel="noopener noreferrer">
                <Instagram title="Instagram" width={26} height={26} />
              </a>
              <a className={classes.socialLink} href="https://www.facebook.com/repairrebels" target="_blank" rel="noopener noreferrer">
                <Facebook title="Facebook" width={26} height={26} />
              </a>
              <a className={classes.socialLink} href="https://www.linkedin.com/company/repair-rebels" target="_blank" rel="noopener noreferrer">
                <LinkedIn title="LinkedIn" width={26} height={26} />
              </a>
              <a className={classes.socialLink} href="https://wa.me/491735204782" target="_blank" rel="noopener noreferrer">
                <WhatsApp title="WhatsApp" width={26} height={26} />
              </a>
              <a className={classes.socialLink} href="https://www.tiktok.com/@repair_rebels" target="_blank" rel="noopener noreferrer">
                <TikTok title="TikTok" width={26} height={26} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
